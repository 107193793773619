.select2-selection {
  font-size: 0.875rem !important;
  min-height: 31px !important;
  padding: .25rem 1.75rem 0.25rem .5rem !important;
}
.select2-container--bootstrap4 .select2-results__option {
  font-size: 0.875rem !important;
  min-height: calc(.75em + .75rem);
  padding: 0 .75rem !important;
}
.select2-container--bootstrap4 .select2-search--dropdown .select2-search__field {
  font-size: 0.875rem !important;
  padding: .25rem .75rem !important;
}
