/*
 * Make the image fully responsive
 */
.carousel-inner img {
  width: 100%;
  height: 100%;
}

/*
 * flip dropdown up-down arrow
 */
nav .nav-item.dropdown i.bi-chevron-down {
  transition: all 0.4s ease;
}
nav .nav-item.dropdown.show i.bi-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.4s ease;
}

.nav-pills>li+li {
  margin-left: 5px; /* 2px by default */
}

.nav-tabs>li+li {
  margin-left: 1px;
}

/*
 * adjust column-count for .card-columns
 */
@media (min-width: 576px) {
  .card-columns { 
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1 !important;
  } 
}

@media (min-width: 768px) {
  .card-columns { 
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2 !important;
  } 
}

/*
 * Background color utilties
 */
.text-gray-1 { color: #f8f9fa!important; }
.text-gray-2 { color: #e9ecef!important; }
.text-gray-3 { color: #dee2e6!important; }
.text-gray-4 { color: #ced4da!important; }
.text-gray-5 { color: #adb5bd!important; }
.text-gray-6 { color: #868e96!important; }
.text-gray-7 { color: #495057!important; }
.text-gray-8 { color: #343a40!important; }
.text-gray-9 { color: #212529!important; }
.text-black  { color: #000!important;    }

/*
 * alert box
 */
.alert {
  margin-bottom: 0.25rem !important;
  padding: 0.5rem 0.75rem !important;
}
.alert-dismissible .close {
  margin-bottom: 0.25rem !important;
  padding: 0.375rem 0.5rem !important;
}

/*
 * Disable box-shadow
 */
.btn:focus, .form-control:focus {
  box-shadow: none !important;
}

/*
 * DropDown Checkbox
 */
.checkbox-menu li label {
  display: block;
  padding: 0 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin:0;
  transition: background-color .4s ease;
}
.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
}
.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #f5f5f5;
}
.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #b8b8ff;
}
